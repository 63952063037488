import React, { useEffect, useRef, useState } from 'react';
import Page from 'Common/shared-ui/src/components/Page';
import dayjs from 'dayjs';
import { useParams, Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import clsx from 'clsx';
import useStyles from './ProjectPage.styles';
import { useNavigate } from 'react-router';
import { generateRedirectUrl } from 'Common/utils/getRedirectUrl';
import {
  closeProjectById,
  getProject,
  getProjectPermissions,
  getProjectReport,
  getProjectStatistic,
  openProjectById,
} from '../../../../packages/common/api';
import { getTranslatedText } from 'Common/utils/getTranslatedText';
import {
  CircularProgress,
  Tabs,
  Box,
  Tab,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ProjectOpenIcon from '../../../../packages/common/shared-ui/src/icons/ProjectOpenIcon';
import ProjectEditIcon from '../../../../packages/common/shared-ui/src/icons/ProjectEditIcon';
import ProjectCloseIcon from '../../../../packages/common/shared-ui/src/icons/ProjectCloseIcon';
import ProjectUserGroupeIcon from '../../../../packages/common/shared-ui/src/icons/ProjectUserGroupeIcon';
import { toggleDrawer, setProject } from '../../app/redux/reducers/userSlice';
const ProjectPage = () => {
  const { slug } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();
  const redmineToken = useSelector(state => state.user.redmine_access_token);
  const [projectData, setProjectData] = useState({});
  const [projectUserPermissions, setProjectUserPermissions] = useState({});
  const [projectDataStatistic, setProjectDataStatistic] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);
  const navigate = useNavigate();
  const [status, setStatus] = React.useState('');
  const handleChangeStatus = (event, nextStatus) => {
    setStatus(nextStatus);
    if (nextStatus == '1') {
      openProjectById(projectData.redmineId);
    } else {
      closeProjectById(projectData.redmineId);
    }
  };
  const handelGoLink = link => {
    window.open(link, '_self');
  };
  const handelGoTrackerLink = id => {
    window.open(link, '_blank');
  };
  const handleGoEditPage = project => {
    const projectUrl = '/projects/' + projectData.identifier + '/edit';
    navigate(projectUrl);
  };
  const handelGoUserLink = id => {
    const link = 'https://opt2.rbtechnologies.ru:9909/users/' + id;
    window.open(link, '_blank');
  };
  const handelGoProjectLink = project => {
    const projectUrl = '/projects/' + project;
    navigate(projectUrl);
  };
  const handelGoMemberLink = member => {
    const memberUrl = '/users/' + member;
    let hostUrl = generateRedirectUrl({ host: window.location.origin, path: memberUrl, redmineToken });
    window.open(hostUrl, '_blank');
  };
  const handelGoFieldLink = field => {
    let hostUrl = '';
    if (field.fieldFormat == 'user') {
      const memberUrl = '/users/' + field.valueId;
      hostUrl = generateRedirectUrl({ host: window.location.origin, path: memberUrl, redmineToken });
      window.open(hostUrl, '_blank');
    } else if (field.fieldFormat == 'attachment') {
      hostUrl = generateRedirectUrl({ host: window.location.origin, path: field.valueId, redmineToken });
      const link = document.createElement('a');
      link.href = hostUrl;
      link.setAttribute('download', field.valueName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      hostUrl = field.valueId;
      window.open(hostUrl, '_blank');
    }
  };

  useEffect(() => {
    async function fetchProject() {
      try {
        let response = await getProject(slug);
        setStatus(response.status);
        response.children = response.children.sort((a, b) => (a.name > b.name ? 1 : -1));
        let projectStatistic = await getProjectStatistic(response.redmineId);
        projectStatistic.rows = projectStatistic.rows.map(item => {
          let trackerLink = `/issues?project=${response.redmineId}&filters=issue.tracker%3Beq%3B${item.rowId}`;
          item.columns[0].link = trackerLink;
          return item;
        });
        setProjectDataStatistic(projectStatistic);
        try {
          const data = await getProjectReport(response.redmineId);
          response.reports = data;
          setProjectData(response);
        } catch (error) {
          console.error('ERROR WITH FETCH PROJECT REPORTS', error);
        } finally {
          setIsLoading(false);
        }
        try {
          const data = await getProjectPermissions(response.redmineId);
          setProjectUserPermissions(data);
        } catch (error) {
          console.error('ERROR WITH FETCH PROJECT USER PERMISSIONS', error);
        } finally {
          setIsLoading(false);
        }
        dispatch(setProject(response));
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
    fetchProject();

    window.scrollTo(0, 0);
  }, [slug, redmineToken, dispatch]);
  return (
    <>
      {!isLoading ? (
        <Page title={projectData.name} width="100%" className={classes.ProjectsContainerRoot}>
          <Grid container position="relative" className={classes.filtersWrap}>
            <Grid
              container
              item
              marginTop={1}
              spacing={5}
              alignItems="center"
              justifyContent="space-between"
              display="flex"
              position="relative"
              className={classes.projectsPageTtl}
            >
              <Typography variant="h1">{projectData.name}</Typography>
              <Box className={classes.ProjectsStatusChangeButtonBox}>
                {(projectUserPermissions.canEdit || projectUserPermissions.canEditMembers) && status != '5' ? (
                  <IconButton onClick={handleGoEditPage} className={classes.ProjectsViewEditIconWrap}>
                    <ProjectEditIcon width={18} height={16} viewBox="0 0 18 16" />
                  </IconButton>
                ) : null}
                <ToggleButtonGroup
                  orientation="vertical"
                  value={status}
                  exclusive
                  className={classes.ProjectsStatusChangeButtonWrap}
                  onChange={handleChangeStatus}
                >
                  {status == '5' && projectUserPermissions.canCloseProject ? (
                    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                    <Tooltip title="Отрыть проект">
                      <ToggleButton value="1" aria-label="open" className={classes.ProjectsViewChangeButton}>
                        <ProjectOpenIcon width={14} height={19} viewBox="0 0 14 19" />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                  {status == '1' && projectUserPermissions.canCloseProject ? (
                    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                    <Tooltip title="Закрыть проект">
                      <ToggleButton value="5" aria-label="close" className={classes.ProjectsViewChangeButton}>
                        <ProjectCloseIcon width={14} height={19} viewBox="0 0 14 19" />
                      </ToggleButton>
                    </Tooltip>
                  ) : null}
                </ToggleButtonGroup>
              </Box>
            </Grid>
          </Grid>
          {status == '5' ? (
            <Box className={clsx(classes.ProjectsTabsWrap, classes.projectCloseMessage)}>
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              <ProjectCloseIcon className={classes.ProjectCloseIcon} width={14} height={19} viewBox="0 0 14 19" />{' '}
              Проект закрыт и находится в режиме только для чтения
            </Box>
          ) : null}
          <Box className={classes.ProjectsTabsWrap}>
            <Grid container marginTop={5} gap={10} position="relative">
              <Grid item xs={5.5} className={classes.projectWrap}>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Свойства проекта
                  </Typography>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Описание:</Box>
                      <Box className={classes.projectFieldRight}>{projectData.description}</Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Уникальный индификатор:</Box>
                      <Box className={classes.projectFieldRight}>{projectData.identifier}</Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Стартовая страница:</Box>
                      <Box className={classes.projectFieldRight}>{projectData.homepage}</Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Общедоступный:</Box>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldRight}>{projectData.isPublic ? 'Да' : 'Нет'}</Box>
                    </Box>
                  </Box>
                  <Box className={classes.projectFieldWrap}>
                    <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                      {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                      <Box className={classes.projectFieldLeft}>Родительский проект:</Box>
                      <Box
                        className={clsx(classes.projectFieldRight, classes.Url)}
                        onClick={() => handelGoProjectLink(projectData.parent.projectIdentifier)}
                      >
                        {projectData.parent?.projectName}
                      </Box>
                    </Box>
                  </Box>
                  {projectData.hasOwnProperty('calculationType') && (
                    <Box className={classes.projectFieldWrap}>
                      <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                        {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                        <Box className={classes.projectFieldLeft}>Тип расчета:</Box>
                        <Box className={classes.projectFieldRight}>{projectData.calculationType}</Box>
                      </Box>
                    </Box>
                  )}
                  {projectData.customs.map((field, index) => (
                    <Box className={classes.projectFieldWrap} key={index}>
                      <Box className={clsx(classes.projectFiledTtl, classes.projectFieldRow)}>
                        <Box className={classes.projectFieldLeft}>{field.fieldName}:</Box>
                        <Box
                          className={clsx(
                            classes.projectFieldRight,
                            field.fieldFormat == 'user' ||
                              field.fieldFormat == 'link' ||
                              field.fieldFormat == 'attachment'
                              ? classes.Url
                              : '',
                          )}
                          onClick={() =>
                            field.fieldFormat == 'user' ||
                            field.fieldFormat == 'link' ||
                            field.fieldFormat == 'attachment'
                              ? handelGoFieldLink(field)
                              : undefined
                          }
                        >
                          {field.valueName}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.projectWrap}>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Задачи
                  </Typography>
                  <TableContainer className={classes.ProjectsListViewTableRootWrap}>
                    <Table className={classes.ProjectsListViewTableRoot}>
                      <TableHead align="left">
                        <TableRow className={classes.tableRow}>
                          {projectDataStatistic.fields.map((field, index) => (
                            <TableCell className={classes.tableCellHead} key={index}>
                              <Typography variant="h4" fontWeight={700}>
                                {getTranslatedText(intl, 'project', field.fieldId, field.fieldName)}
                              </Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {projectDataStatistic.rows.map((project, index) => (
                          <TableRow key={index}>
                            {project.columns.map((field, index) => (
                              <TableCell key={index} className={classes.tableCell}>
                                <Typography
                                  className={field.link ? classes.Url : ''}
                                  onClick={() => (field.link ? handelGoLink(field.link) : undefined)}
                                  variant="h4"
                                >
                                  {field.value}{' '}
                                </Typography>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Подпроекты
                  </Typography>
                  {projectData.children.map((project, index) => (
                    <Box className={classes.projectFieldMemberWrap} key={index}>
                      <Typography
                        variant="h5"
                        className={clsx(
                          project.status === 1 ? classes.projectFiledValueOpened : classes.projectFiledValueClosed,
                          classes.projectFiledValue,
                          classes.Url,
                        )}
                        onClick={() => handelGoProjectLink(project.identifier)}
                      >
                        {project.name}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
                <Grid container spacing={5} position="relative" className={classes.projectFieldsWrap}>
                  {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                  <Typography className={classes.projectFieldsTtl} variant="h3">
                    Участники
                  </Typography>
                  {projectData.members.map((field, index) => (
                    <Box className={classes.projectFieldMemberWrap} key={index}>
                      {field.isGroup ? (
                        <ProjectUserGroupeIcon
                          className={classes.ProjectUserGroupeIcon}
                          width={16}
                          height={10}
                          viewBox="0 0 16 10"
                        />
                      ) : null}
                      <Typography
                        variant="h5"
                        className={clsx(
                          field.isActive ? classes.projectFiledTtlActiveUsers : classes.projectFiledTtlBlockedUsers,
                          classes.projectFiledTtl,
                          field.isGroup ? '' : classes.Url,
                        )}
                        onClick={() => (field.isGroup ? '' : handelGoMemberLink(field.userId))}
                      >
                        {field.userName}
                      </Typography>
                      <Box className={classes.projectFieldMemberRolesWrap} key={index}>
                        {field.roles.map((role, index) => (
                          <Typography key={index} variant="h5" className={classes.projectFiledValue}>
                            {role.roleName}
                          </Typography>
                        ))}
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};
export default injectIntl(ProjectPage);
