import { makeStyles } from '@mui/styles';

const dataGridTableStyles = makeStyles(theme => ({
  DataGridTableRoot: {
    width: '100%',
    overflow: 'hidden',
    '&.MuiDataGrid-root': {
      border: 0,
      '& .MuiDataGrid-columnHeaders': {
        borderBottom: '1px solid #E4E4EF',
        '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
          '&.tableGroupGanttHeaderCell': {
            //minWidth: '280px',
            //maxWidth: '280px',
          },
          '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
            '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
              borderLeft: '1px solid #E4E4EF',
            },
          },
        },
        '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor.MuiDataGrid-columnHeaderCheckbox': {
          minWidth: '36px!important',
          maxWidth: '36px!important',
          '& + .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
            paddingLeft: 0,
          },
        },
      },
      '& .MuiDataGrid-virtualScroller': {
        '&::-webkit-scrollbar': {
          height: '6px',
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#7174AC',
          borderRadius: '5px',
        },
      },
      '& .MuiDataGrid-row': {
        width: '100%',
        borderBottom: '1px solid #E4E4EF',
        '&.errorRow': {
          outline: '1px solid #E03737',
        },
        '& .MuiDataGrid-cell': {
          '&.tableCell': {
            overflow: 'visible',
            '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
              '& + .MuiDataGrid-cell.tableCell': {
                borderLeft: '1px solid #E4E4EF',
              },
            },
            '&.tableGroupCell': {
              overflow: 'visible',
              whiteSpace: 'nowrap',
              '&:not(.MuiDataGrid-columnHeaderCheckbox)': {
                '& + .MuiDataGrid-cell.tableCell': {
                  borderLeft: '1px solid transparent',
                },
              },
            },
            '&.tableGroupGanttCell': {
              padding: '10px 10px 10px 0',
              borderLeft: '1px solid #E4E4EF',
              //minWidth: '280px',
              //maxWidth: '280px',
            },
          },
        },
        '& .MuiDataGrid-cell.MuiDataGrid-withBorderColor.MuiDataGrid-cellCheckbox': {
          minWidth: '36px!important',
          maxWidth: '36px!important',
          '& + .MuiDataGrid-cell': {
            paddingLeft: 0,
          },
        },
        '&.MuiDataGrid-row--editing': {
          boxShadow: 'none',
        },
      },
      '& .MuiDataGrid-row.groupRow': {
        '&.confirmationRow': {
          borderTop: '4px solid #E4E4EF',
          '&.first-row': {
            borderTop: 'none',
          },
        },
        '& .MuiDataGrid-cellCheckbox': {
          boxShadow: 'none',
          '& .MuiCheckbox-root': {
            visibility: 'hidden',
          },
        },
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:last-child': {
        borderRight: 0,
      },
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
        backgroundColor: '#DCDFF4',
      },
      '& .MuiDataGrid-main .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
        marginLeft: theme.spacing(2),
      },
      '& .MuiCheckbox-root': {
        '& .MuiSvgIcon-root': {
          width: '12px',
        },
      },
      '& .MuiDataGrid-columnSeparator': {
        '& .MuiSvgIcon-root': {
          width: '24px',
        },
      },
      '& .MuiDataGrid-cell': {
        borderBottom: 'none',
        '&.MuiDataGrid-cell--editing': {
          boxShadow: 'none',
          '&:focus-within': {
            outline: 'none',
          },
        },
        '&.tableCell': {
          wordBreak: 'break-word',
          borderColor: 'transparent',
          position: 'relative',
          padding: '10px',
          '&.tableSelectCell': {
            overflow: 'visible',
          },
          '& a': {
            position: 'relative',
          },
          '&:last-child': {
            boxShadow: 0,
          },
          '& .MuiCheckbox-root': {
            padding: 0,
          },
        },
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      '& .MuiDataGrid-cellCheckbox': {
        borderColor: 'transparent',
        //boxShadow: '1px 1px #E4E4EF',
      },
      '& .MuiDataGrid-main': {
        borderRadius: '16px',
        backgroundColor: '#fff',
      },
    },
    '& .MuiDataGrid-footerContainer': {
      marginTop: '20px',
      minHeight: 'auto',
      border: 'none',
      justifyContent: 'flex-start',
      '& .MuiToolbar-root.MuiTablePagination-toolbar': {
        minHeight: 'auto',
        padding: 0,
        '& .MuiPagination-root': {
          marginLeft: 0,
          '& li': {
            fontSize: '16px',
            lineHeight: '20px',
          },
          '& li + li': {
            marginLeft: '2px',
          },
          '& .MuiButtonBase-root.MuiPaginationItem-root': {
            color: '#41424E',
            fontSize: '16px',
            lineHeight: '20px',
            borderRadius: '4px',
            minWidth: '28px',
            height: '28px',
            margin: 0,
            '&.Mui-selected': {
              backgroundColor: '#6270D0',
              color: '#FAFAFC',
              '&:hover': {
                color: '#FAFAFC',
                backgroundColor: '#6270D0',
              },
            },
            '& .MuiSvgIcon-root.MuiPaginationItem-icon': {
              width: 'auto',
              height: 'auto',
            },
            '&:hover': {
              color: '#6270D0',
              backgroundColor: 'transparent',
            },
          },
          '& .MuiPaginationItem-ellipsis': {
            fontSize: '16px',
            lineHeight: '20px',
            color: '#41424E',
            padding: 0,
            margin: 0,
            minWidth: ' 28px',
          },
        },
      },
    },
  },
  FixedHeaderDataGridTableRoot: {
    '&.MuiDataGrid-root': {
      position: 'static',
      '& .MuiDataGrid-main': {
        position: 'static',
        borderRadius: 0,
        '& .MuiDataGrid-virtualScroller': {
          paddingTop: '56px',
        },
      },
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#fff',
        zIndex: 1,
        position: 'absolute',
      },
    },
  },
  selectLabel: {
    '&.MuiFormLabel-root.MuiInputLabel-root': {
      color: '#7174AC',
      fontSize: '14px',
      lineHeight: '20px',
      transform: 'none',
      '&.Mui-focused': {
        transform: 'none',
      },
      '&.MuiFormLabel-filled': {
        display: 'none',
      },
    },
  },
  datePicker: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      height: '100%',
      '& .MuiInputBase-input.MuiOutlinedInput-input': {
        padding: 0,
        color: '#7174AC',
        fontSize: '12px',
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.changed': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          color: '#212346',
        },
      },
    },
  },
  rowSelect: {
    '&.MuiInputBase-root.MuiInput-root': {
      marginTop: 0,
      width: '100%',
      fontSize: '14px',
      lineHeight: '20px',
      height: '100%',
      padding: '0',
      color: '#7174AC',
      '&.changed': {
        color: '#212346',
      },
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiSelect-select': {
        padding: 0,
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        width: '20px',
        height: '20px',
        position: 'static',
      },
      '& .MuiSelect-nativeInput': {
        padding: '0',
      },
      '&.Mui-error': {
        border: '1px solid #E03737',
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  rowInput: {
    '& .MuiInputBase-root.MuiInput-root': {
      fontSize: '14px',
      lineHeight: '20px',
      '&::before': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&::before': {
          borderBottom: 'none',
        },
      },
      '& .MuiInputBase-input.MuiInput-input': {
        color: '#7174AC',
        padding: 0,
        '&::placeholder': {
          color: '#7174AC',
          opacity: 1,
        },
      },
      '&.Mui-error': {
        border: '1px solid #E03737',
        '&::after': {
          display: 'none',
        },
      },
    },
    '&.changed': {
      '& .MuiInputBase-root.MuiInput-root': {
        '& .MuiInputBase-input.MuiInput-input': {
          color: '#212346',
        },
      },
    },
  },
  paperDropdown: {
    '&.MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
      maxHeight: '300px',
      borderRadius: '0 0 16px 16px',
      borderTop: '1px solid #E4E4EF',
      boxShadow: '0px 5px 10px -8px #A0A1AA66',
      '&::-webkit-scrollbar': {
        width: '2px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#7174AC',
        borderRadius: '5px',
      },
      '& .MuiList-root.MuiMenu-list': {
        padding: '0',
        '& li': {
          fontSize: '14px',
          lineHeight: '20px',
          color: '#212346',
          '&.MuiButtonBase-root.MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: '#DCDFF4',
            },
          },
        },
      },
    },
  },
  rowAutocomlpete: {
    '&.MuiAutocomplete-root': {
      width: '100%',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '0',
      lineHeight: '20px',
      height: 'auto',
      '& .MuiAutocomplete-endAdornment': {
        right: 0,
        top: 'calc(50% - 12px)',
        '& .MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator': {
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
        '& .MuiSvgIcon-root': {
          width: '20px',
          height: '20px',
          position: 'static',
        },
      },
      '& .MuiAutocomplete-input': {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#212346',
        padding: '0',
        height: 'auto',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        padding: 0,
        border: 'none',
      },
    },
    '&.Mui-expanded': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        borderRadius: '16px 16px 0 0',
        '& .MuiOutlinedInput-notchedOutline': {
          padding: 0,
        },
      },
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '&.Mui-focused': {
      '& .MuiFormLabel-root.MuiInputLabel-root': {
        display: 'none',
      },
    },
    '& + .MuiAutocomplete-popper': {
      minWidth: 'auto!important',
      //width: '100%!important',
      //maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        //border: '1px solid #E4E4EF',
        borderTop: 'none',
        boxShadow: '0px 5px 10px -8px rgba(160, 161, 170, 1)',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
    '&.changed': {
      '& .MuiInputBase-root.MuiOutlinedInput-root': {
        '& .MuiAutocomplete-input': {
          color: '#212346',
        },
      },
    },
  },
  rowAutocomlpetePopper: {
    '&.MuiPopper-root': {
      minWidth: 'auto!important',
      //width: '100%!important',
      //maxWidth: '300px!important',
      '& .MuiPaper-root.MuiAutocomplete-paper': {
        borderRadius: '0 0 16px 16px',
        //border: '1px solid #E4E4EF',
        borderTop: 'none',
        boxShadow: '0px 5px 10px -8px rgba(160, 161, 170, 1)',
        '& .MuiAutocomplete-noOptions': {
          padding: '8px 16px',
          color: '#212346',
          fontSize: '12px',
          lineHeight: '20px',
        },
        '& .MuiAutocomplete-listbox': {
          padding: '0',
          '& li': {
            whiteSpace: 'normal',
            fontSize: '12px',
            lineHeight: '20px',
            color: '#212346',
            '&.Mui-focused': {
              backgroundColor: 'transparent',
            },
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            '&.Mui-selected': {
              backgroundColor: '#DCDFF4',
            },
          },
          '&::-webkit-scrollbar': {
            width: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#7174AC',
            borderRadius: '5px',
          },
        },
      },
    },
  },
  rowAutocomlpetePadding: {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      padding: '10px',
      '& .MuiAutocomplete-endAdornment': {
        width: '24px',
        right: '9px',
      },
    },
  },
}));

export default dataGridTableStyles;
