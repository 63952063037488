import React, { useEffect } from 'react';
import { generateRedirectUrl } from '../../../packages/common/utils/getRedirectUrl';
import { useNavigate } from 'react-router';

const RedirectToRedmine = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/projects');
  }, [navigate]);
  return null;
};

export default RedirectToRedmine;
