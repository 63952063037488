import { makeStyles } from '@mui/styles';

const objectsHierarchyStyles = makeStyles(theme => ({
  blockWrapper: {
    //flex: 1,
    //height: '100%',
    overflow: 'auto',
    marginTop: '16px',
    backgroundColor: '#fff',
    borderRadius: '16px',
    '&::-webkit-scrollbar': {
      height: '6px',
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#7174AC',
      borderRadius: '5px',
    },
  },
  columnHeader: {
    borderBottom: '1px solid #E4E4EF',
    padding: '16px 10px',
    //position: 'absolute',
  },
  columnItem: {
    transition: '.2s ease',
    '&:hover': {
      boxShadow: 'inset 0px 0px 0px 1px rgba(228,228,239,1)',
    },
  },
}));

export default objectsHierarchyStyles;
