import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import issuePageStyles from '../../issuePage/IssuePage.styles';
import useStyles from '../../resourcePlan/ResourcePlanHeader/ResourcePlanHeader.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import Page from 'Common/shared-ui/src/components/Page';
import { ArrayParam, useQueryParams } from 'use-query-params';
import { getCookie } from 'Common/utils/cookies';
import { getTranslatedText } from '../../../../packages/common/utils/getTranslatedText';
import dayjs from 'dayjs';
import { Gantt } from 'ju-gantt-task-react';
import 'ju-gantt-task-react/dist/style.css';
import GanttIssueModal from '../components/GanttIssueModal/GanttIssueModal';
import GanttEditCellUser from '../components/GanttEditCellUser/GanttEditCellUser';
import GanttIssueRelatedModal from '../components/GanttIssueRelatedModal/GanttIssueRelatedModal';
import FilterIcon from '../../../../packages/common/shared-ui/src/icons/FilterIcon';
import GridFiltersModal from '../../GridFiltersModal/GridFiltersModal';
import {
  clearGanttSave,
  getaGanttIssueTrackers,
  getaGanttIssueTrackersAssignees,
  getGantCsv,
  getGantFilters,
  getGanttMovingIssue,
  getIssuePredecessors,
  saveGanttSettings,
} from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import {
  DataGridPro,
  GRID_TREE_DATA_GROUPING_FIELD,
  gridFilteredDescendantCountLookupSelector,
  useGridApiRef,
  useGridSelector,
  gridFilteredSortedRowIdsSelector,
  GridCellEditStopReasons,
  GridRowId,
  GridRowTreeNodeConfig,
} from '@mui/x-data-grid-pro';
import dataGridTableStyles from '../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import GridFiltersPanel from '../../GridFiltersPanel/GridFiltersPanel';
import { filtersToQuery } from '../../../../packages/common/utils/gridFiltersHelper';
import clsx from 'clsx';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import issueFormFieldStyles from '../../issueCreateEdit/components/IssueFormField.styles';
import ganttContainerStyles from './GanttContainer.styles';
import ZoomInIcon from '../../../../packages/common/shared-ui/src/icons/ZoomInIcon';
import ZoomOutIcon from '../../../../packages/common/shared-ui/src/icons/ZoomOutIcon';
import ZoomCancelIcon from '../../../../packages/common/shared-ui/src/icons/ZoomCancelIcon';
import UncheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/UncheckedRadioIcon';
import CheckedRadioIcon from '../../../../packages/common/shared-ui/src/icons/CheckedRadioIcon';
import { useWebSockets } from '../../resourcePlan/useWebsockets';
import DownloadButton from '../../../../packages/common/shared-ui/src/icons/DownloadButton';

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}
const GanttContainer = () => {
  const issueCreateEditClasses = issueFormFieldStyles();
  const issueCommonStyles = issuePageStyles();
  const ViewModeEnum = useMemo(() => {
    return ['Hour', 'Half Day', 'Day', 'Week', 'Month', 'Year'];
  }, []);
  const expansionState = React.useRef({});
  const classes = useStyles();
  const ganttStyle = ganttContainerStyles();
  const dataGridTableStyle = dataGridTableStyles();
  const [rowsToUpdate, setRowsToUpdate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [firstLoadGroup, setFirstLoadGroup] = useState(true);
  const [open, setOpen] = useState(false);
  const [rightGantState, setRightGantState] = useState(1);
  const [openGantIssueModal, setOpenGantIssueModal] = useState(false);
  const [openGantIssueRelatedModal, setOpenGantIssueRelatedModal] = useState(false);
  const [openParamsModal, setOpenParamsModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [query, setQuery] = useQueryParams({
    project: '',
    filters: ArrayParam,
  });
  const formatDate = useCallback(dateStr => {
    if (dateStr) {
      return dayjs(dateStr).format('DD.MM.YYYY');
    }
    return dateStr;
  }, []);
  const intl = useIntl();
  const apiRef = useGridApiRef();
  const dataGridProRef = useRef(null);
  const accessToken = getCookie('access_token');
  const pageTitle = intl.formatMessage({ id: 'page_title_gant' });
  const expansionLookup = useRef({});
  const [ganttRightSideData, setGanttRightSideData] = useState([]);
  const [ganttTableData, setGanttTableData] = useState([]);
  const [ganttTableShowChilds, setGanttTableShowChilds] = useState(true);
  const [ganttHideTask, setGanttHideTask] = useState();
  const [ganttTableZoom, setGanttTableZoom] = useState();
  const [ganttTableRowData, setGanttTableRowData] = useState([]);
  const [ganttTableColumnsData, setGanttTableColumnsData] = useState([]);
  const [ganttTableSelectedValues, setGanttTableSelectedValues] = useState([]);
  const [ganttIssueFields, setGanttIssueFields] = useState([]);
  const [ganttTableViewMode, setGanttTableViewMode] = useState(0);
  const [ganttTableViewModeEnum, setGanttTableViewModeEnum] = useState(ViewModeEnum['0']);
  const [ganttModalData, setGanttModalData] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeFields, setActiveFields] = useState({ availableFields: [], selectedFields: [] });
  const [selectedSignatures, setSelectedSignatures] = useState([]);
  const [selectedPlanType, setSelectedPlanType] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [editIssueData, setEditIssueData] = useState(null);
  const [contextSubMenu, setContextSubMenu] = useState(null);
  const [movingIssueOptions, setMovingIssueOptions] = useState([]);
  const [trackers, setTrackers] = useState([]);
  const [rowUsers, setRowUsers] = useState(null);
  const [predecessors, setPredecessors] = useState([]);
  const [showSavingError, setShowSavingError] = useState(false);
  const [gantErrorValue, setGanttErrorValue] = useState('save_all');
  const [ganttSavingLoader, setGanttSavingLoader] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [ganttRowsErrors, setGanttRowsErrors] = useState([]);
  const [ganttDateErrors, setGanttDateErrors] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const socket = useWebSockets();

  useEffect(() => {
    async function getTrackers(projectId) {
      const { data: trackers } = await getaGanttIssueTrackers(projectId);
      //console.log('getTrackers');
      //console.log(trackers);
      setTrackers(trackers);
      return trackers;
    }
    async function getUsers(trackerId, projectId) {
      const { data: usersOptions } = await getaGanttIssueTrackersAssignees({
        tracker: trackerId,
        project: projectId,
      });
      return usersOptions;
    }
    if (query.project) {
      let usersData = {};
      getTrackers(query.project)
        .then(res => {
          res.forEach((tracker, index, array) => {
            getUsers(tracker.trackerId, query.project).then(users => {
              usersData[tracker.trackerId] = users;
              if (index === array.length - 1) {
                setRowUsers(usersData);
              }
            });
          });
        })
        .catch(error => console.log(error.response));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.project, setTrackers]);

  const navigate = useNavigate();
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  const [width, setWidth] = useState(50);

  const dragHandler = useCallback(
    e => {
      const w =
        ((e.clientX - e.currentTarget.parentElement.parentElement.getBoundingClientRect().left) /
          e.currentTarget.parentElement.parentElement.getBoundingClientRect().width) *
        100;
      if (w > 0 && w !== width) {
        setWidth(w);
      }
    },
    [width],
  );

  useEffect(() => {
    if (socket) {
      if (isLoading) {
        let params = {};
        query.filters && (params.filters = query.filters.join('$$'));
        socket.emit('getGanttTable', {
          token: accessToken,
          query: {
            filters: params.filters,
          },
          projectId: query.project,
        });
      }
    }
  }, [accessToken, query.project, socket, isLoading, query.filters]);

  const cellRenderer = useCallback(
    ({ row, column, canEdit }) => {
      //console.log(column);
      let fieldData = row[column.fieldId];
      if (column.fieldType === 'user') {
        fieldData = fieldData instanceof Object ? fieldData.valueName : fieldData;
      }
      if (fieldData && column.fieldType === 'date' && typeof fieldData === 'string') {
        fieldData = formatDate(fieldData);
      }
      if (fieldData && (column.fieldType === 'cost_fact' || column.fieldType === 'labor_fact')) {
        fieldData = fieldData.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',');
      } else if (fieldData === 0 && (column.fieldType === 'cost_fact' || column.fieldType === 'labor_fact')) {
        fieldData = '0,00';
      }
      if (fieldData && (column.fieldType === 'monthly_distribution' || column.fieldType === 'loading_percent')) {
        fieldData = parseFloat(fieldData)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
          .replace('.', ',');
      } else if (
        fieldData === 0 &&
        (column.fieldType === 'monthly_distribution' || column.fieldType === 'loading_percent')
      ) {
        fieldData = '0,00';
      }
      if (
        column.fieldType === 'monthly_distribution' ||
        column.fieldType === 'loading_percent' ||
        column.fieldType === 'cost_fact' ||
        column.fieldType === 'labor_fact' ||
        column.fieldType === 'date'
      ) {
        return (
          <Box align="left">
            <Tooltip
              title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {fieldData}
              </Typography>
            </Tooltip>
          </Box>
        );
      } else if (column.fieldType === 'issues') {
        return (
          <Box align="left">
            <Tooltip
              title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <a
                href={handleGetRedirectUrl({
                  id: row.services.issueId,
                  columnType: 'issue',
                })}
                className={classes.tableCellLink}
                target="_blank"
              >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {row[column.fieldId]}
                </Typography>
              </a>
            </Tooltip>
          </Box>
        );
      } else if (column.fieldType === 'issueId') {
        return (
          <Box align="left" overflow={'hidden'}>
            {row.services.isNew || row.kind === 'project' ? (
              ''
            ) : (
              <Tooltip
                title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
                PopperProps={{ className: classes.commentTooltip }}
              >
                <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                  {fieldData}
                </Typography>
              </Tooltip>
            )}
          </Box>
        );
      } else {
        return (
          <Box align="left" overflow={'hidden'}>
            <Tooltip
              title={getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName)}
              PopperProps={{ className: classes.commentTooltip }}
            >
              <Typography textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" variant="h5">
                {fieldData}
              </Typography>
            </Tooltip>
          </Box>
        );
      }
    },
    [classes.commentTooltip, classes.tableCellLink, formatDate, intl],
  );

  useEffect(() => {
    if (rowsToUpdate?.length > 0) {
      setTimeout(() => {
        apiRef.current.updateRows(rowsToUpdate);
      }, 500);
    }
  }, [apiRef, rowsToUpdate]);

  const handleField = useCallback(
    (fieldValue, props) => {
      const numberFields = ['float', 'int', 'percent'];
      const groupId = props.row.kind === 'project' ? props.row.id : props.rowNode.parent;
      let date;
      let newValue = fieldValue;
      if (props.field === 'issue.start' || props.field === 'issue.end') {
        newValue = fieldValue && fieldValue.$d ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '';
      }
      props.api.setEditCellValue({
        id: props.id,
        field: props.field,
        value: newValue,
      });
      //console.log('handleField');
      //console.log(fieldValue);
      //console.log(props);
      if (socket) {
        switch (props.field) {
          case 'issue.subject':
            socket.emit('updateGanttNameAndUser', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                name: fieldValue,
                userId: null,
              },
            });
            break;
          case 'issue.assigned':
            socket.emit('updateGanttNameAndUser', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                name: props.row['issue.subject'],
                userId: Number(newValue.valueId),
              },
            });
            break;
          case 'issue.duration':
            //console.log('updateGanttDuration');
            //console.log(Number(fieldValue));
            socket.emit('updateGanttDuration', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                duration: Number(fieldValue.replace(',', '.')),
              },
            });
            break;
          case 'issue.loading_percent':
            //console.log('updateGanttLoadingPercent');
            //console.log(Number(fieldValue));
            socket.emit('updateGanttLoadingPercent', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                loadingPercent: Number(fieldValue.replace(',', '.')),
              },
            });
            break;
          case 'issue.estimated_hours':
            //('updateGanttEstimatedHours');
            //console.log(Number(fieldValue));
            socket.emit('updateGanttEstimatedHours', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                estimatedHours: Number(fieldValue.replace(',', '.')),
              },
            });
            break;
          case 'issue.start':
            //console.log('updateGanttStartDate');
            //console.log(dayjs(fieldValue.$d).format('YYYY-MM-DD'));
            socket.emit('updateGanttStartDate', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                updateDate: fieldValue && fieldValue.$d ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '',
              },
            });
            break;
          case 'issue.end':
            //console.log('updateGanttEndDate');
            //console.log(dayjs(fieldValue.$d).format('YYYY-MM-DD'));
            setGanttDateErrors(null);
            socket.emit('updateGanttEndDate', {
              token: accessToken,
              rowId: props.id,
              groupId: props.row.project.toString(),
              projectId: props.row.project,
              updatedValue: {
                updateDate: fieldValue && fieldValue.$d ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '',
              },
            });
            break;
        }
      }
      /*const index = confirmationDocsRows.findIndex(item => item.id === id);
      let updatedObject;
      fieldValue?.target &&
        (updatedObject = {
          ...confirmationDocsRows[index],
          ['confirmation.fieldRenewValue']: fieldValue.target.value,
          ['confirmation.fieldRenewValueId']: fieldValue.target.value,
        });
      numberFields.includes(type) &&
        (updatedObject['confirmation.fieldRenewValueId'] = fieldValue.target.value.replace(/\s+/g, ''));
      type === 'date' &&
        (updatedObject = {
          ...confirmationDocsRows[index],
          ['confirmation.fieldRenewValue']: fieldValue ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '',
          ['confirmation.fieldRenewValueId']: fieldValue ? dayjs(fieldValue.$d).format('YYYY-MM-DD') : '',
        });
      type === 'issue_parent' &&
        (updatedObject = {
          ...confirmationDocsRows[index],
          ['confirmation.fieldRenewValue']: fieldValue.valueName,
          ['confirmation.fieldRenewValueId']: fieldValue.valueId,
        });
      updatedObject.changed = true;
      setConfirmationDocsRows([
        ...confirmationDocsRows.slice(0, index),
        updatedObject,
        ...confirmationDocsRows.slice(index + 1),
      ]); */
    },
    [accessToken, socket],
  );

  const formattedGanttColumns = useCallback(
    columns => {
      const issueIdField = {
        instance: 'issue',
        field: 'id',
        fieldId: 'issue.gantt_id',
        fieldName: 'Id',
        fieldType: 'issueId',
        isChecked: true,
        isCustom: false,
        position: 0,
      };

      let newColumns = [issueIdField].concat(columns);
      newColumns = newColumns.map(column => {
        let type = '';
        let editable = false;
        const numberFields = ['float', 'int', 'percent'];
        switch (column.fieldId) {
          case 'issue.subject':
          case 'issue.assigned':
            editable = true;
            break;
          case 'issue.duration':
          case 'issue.loading_percent':
          case 'issue.estimated_hours':
            editable = true;
            type = 'float';
            break;
          case 'issue.start':
          case 'issue.end':
            type = 'date';
            editable = true;
            break;
        }
        return {
          field: column.fieldId,
          headerName: getTranslatedText(intl, 'filter', column.fieldId.split('-').join('_'), column.fieldName),
          renderHeader: props => (
            <Typography variant="h4" fontWeight={700}>
              {props.colDef.headerName}
            </Typography>
          ),
          renderCell: props => {
            const canEdit = props.row?.services?.canEdit;
            const row = props.row;
            return cellRenderer({ row, column, canEdit });
          },
          disableExport: false,
          type: type,
          position: column.position,
          sortable: false,
          editable: editable,
          renderEditCell: props => {
            //const index = ganttTableRowData.findIndex(item => item.id === props.row.id);
            //console.log('trackers+rowUsers');
            //console.log(trackers);
            //console.log(rowUsers);
            //console.log('predecessors');
            //console.log(predecessors);
            if (props.field === 'issue.predecessor') {
              return (
                <Autocomplete
                  noOptionsText={intl.formatMessage({ id: 'not_found' })}
                  onChange={(event, newValue) => handleField(newValue, props)}
                  className={clsx(dataGridTableStyle.rowAutocomlpete, {
                    ['changed']: props.row.changed,
                  })}
                  disablePortal
                  options={predecessors[props.row.id] ? predecessors[props.row.id] : []}
                  disableClearable
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={option.valueId} value={option.valueId}>
                        {option.valueName}
                      </MenuItem>
                    );
                  }}
                  value={props.value ? props.value : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={option => option.valueName || ''}
                  PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={ev => handlePredecessorOptions(ev.target.value, props)}
                      placeholder={intl.formatMessage({ id: 'choose' })}
                    />
                  )}
                  popupIcon={<ChevronIcon direction={'down'} />}
                />
              );
            }
            if (props.field === 'issue.assigned') {
              return <GanttEditCellUser handleField={handleField} cellProps={props} />;
            }
            if (props.colDef.type === 'issue_parent') {
              return (
                <Autocomplete
                  noOptionsText={intl.formatMessage({ id: 'not_found' })}
                  onChange={(event, newValue) => handleField(newValue, props)}
                  className={clsx(dataGridTableStyle.rowAutocomlpete, {
                    ['changed']: props.row.changed,
                  })}
                  disablePortal
                  filterOptions={x => x}
                  options={parentIssueOptions}
                  sx={{ width: 253 }}
                  disableClearable
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={option.valueId} value={option.valueId}>
                        {option.valueName}
                      </MenuItem>
                    );
                  }}
                  value={props.value ? props.value : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={option => option.valueName || ''}
                  PopperComponent={props => <Popper {...props} placement="bottom-start"></Popper>}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={ev => handleParentIssueChange(ev.target.value)}
                      placeholder={intl.formatMessage({ id: 'choose' })}
                    />
                  )}
                  popupIcon={<ChevronIcon direction={'down'} />}
                />
              );
            }
            if (props.field === 'issue.tracker2') {
              return (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel className={dataGridTableStyle.selectLabel} id="select-label">
                    {intl.formatMessage({ id: 'choose' })}
                  </InputLabel>
                  <Select
                    native={false}
                    labelId="select-label"
                    label={intl.formatMessage({ id: 'choose' })}
                    variant="standard"
                    sx={{ width: '100%', border: 'none' }}
                    className={clsx(dataGridTableStyle.rowSelect, {
                      ['changed']: props.row.changed,
                    })}
                    MenuProps={{ classes: { paper: dataGridTableStyle.paperDropdown } }}
                    IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                    value={props.value ? props.value : ''}
                    onChange={e => handleField(e.target.value, props)}
                  >
                    {trackers.map((item, index) => (
                      <MenuItem key={index} value={item.trackerId}>
                        {item.trackerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
            if (numberFields.includes(props.colDef.type)) {
              return (
                <TextField
                  autoFocus
                  defaultValue={props.value ? props.value : ''}
                  className={clsx(dataGridTableStyle.rowInput, {
                    ['changed']: props.row.changed,
                  })}
                  placeholder={intl.formatMessage({ id: 'enter' })}
                  variant="standard"
                  onChange={e => {
                    handleField(e.target.value, props);
                  }}
                ></TextField>
              );
            }
            if (props.colDef.type === 'list' || props.colDef.type === 'user') {
              return (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel className={dataGridTableStyle.selectLabel} id="select-label">
                    {intl.formatMessage({ id: 'choose' })}
                  </InputLabel>
                  <Select
                    native={false}
                    labelId="select-label"
                    label={intl.formatMessage({ id: 'choose' })}
                    variant="standard"
                    sx={{ width: '100%', border: 'none' }}
                    className={clsx(dataGridTableStyle.rowSelect, {
                      ['changed']: props.row.changed,
                    })}
                    MenuProps={{ classes: { paper: dataGridTableStyle.paperDropdown } }}
                    IconComponent={props => <ChevronIcon direction={'down'} {...props} />}
                    value={
                      confirmationDocsRows[index]['confirmation.fieldRenewValueId']
                        ? confirmationDocsRows[index]['confirmation.fieldRenewValueId']
                        : ''
                    }
                    onChange={event => handleField(event, props)}
                  >
                    {props.row.values.map((item, index) => (
                      <MenuItem key={index} value={item.valueId}>
                        {item.valueName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }
            if (props.colDef.type === 'date') {
              console.log('formattedGanttColumns');
              console.log(props.row);
              console.log(props.value);
              return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className={clsx(dataGridTableStyle.datePicker, {
                      ['changed']: props.row.changed,
                    })}
                    format={'DD.MM.YYYY'}
                    value={props.value ? props.value : ''}
                    renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />}
                    autoFocus={true}
                    onChange={value => handleField(value, props)}
                    components={{
                      LeftArrowIcon: () => <ChevronIcon direction="left" viewBox="0 0 24 24" width={24} height={24} />,
                      RightArrowIcon: () => (
                        <ChevronIcon direction="right" viewBox="0 0 24 24" width={24} height={24} />
                      ),
                      RightArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                      LeftArrowButton: componentProps => (
                        <IconButton disableRipple {...componentProps}>
                          {componentProps.children}
                        </IconButton>
                      ),
                    }}
                  />
                </LocalizationProvider>
              );
            }
          },
          /* valueFormatter: params => dayjs(params.value).format('DD.MM.YYYY'),
          valueGetter: params => {
          if (column.fieldId === 'issue.start' || column.fieldId === 'issue.end') {
            console.log('valueGetter');
            console.log(params.value);
            console.log(dayjs(params.value).format('DD.MM.YYYY'));
            console.log(dayjs(params.value, 'DD.MM.YYYY', true).isValid());
            if (dayjs(params.value, 'DD.MM.YYYY', true).isValid()) {
              return params.value;
            } else {
              return dayjs(params.value).format('DD.MM.YYYY');
            }
          } else {
            return params.value;
          }
        },*/
          valueFormatter: params => {
            if (column.fieldId === 'issue.start' || column.fieldId === 'issue.end') {
              if (dayjs(params.value, 'DD.MM.YYYY', true).isValid()) {
                return params.value;
              } else {
                return dayjs(params.value).format('DD.MM.YYYY');
              }
            } else {
              return params.value;
            }
          },
          cellClassName: () => column.fieldId !== 'issue.gantt_id' && 'tableCell',
        };
      });
      //console.log('newColumns');
      //console.log(newColumns);
      return newColumns.toSpliced(1, 0, {
        field: GRID_TREE_DATA_GROUPING_FIELD,
      });
    },
    [
      intl,
      cellRenderer,
      dataGridTableStyle.rowAutocomlpete,
      dataGridTableStyle.selectLabel,
      dataGridTableStyle.rowSelect,
      dataGridTableStyle.paperDropdown,
      dataGridTableStyle.rowInput,
      dataGridTableStyle.datePicker,
      predecessors,
      handleField,
      handlePredecessorOptions,
      trackers,
    ],
  );
  const formattedRightSideData = useCallback(
    rows =>
      rows.map(
        column => {
          let parentForCharts = column.services.projectId;
          let parentForTable = column.services.projectId;
          let user =
            column.signatures.findIndex(item => item.fieldId === 'issue.assigned') > 0 ? column.services.userName : '';
          let popupInfo = column.signatures.filter(item => item.fieldId !== 'issue.assigned');
          if (column.services.hierarchy.length > 2) {
            parentForTable = column.services.parentId;
          }
          let predecessors = column.services.predecessors?.map(predecessor => {
            return {
              sourceId: predecessor.toString(),
              sourceTarget: 'endOfTask',
              ownTarget: 'startOfTask',
            };
          });
          //console.log('formattedRightSideData');
          //console.log(column);
          //console.log(ganttModalData);
          return {
            start: new Date(column.services.startDate),
            end: new Date(column.services.endDate),
            name: column.type === 'issue' ? column.rowName : column.rowName,
            id: column.rowId,
            type: column.type,
            user: user !== null ? user : '',
            popupInfo: popupInfo
              .filter(notEmpty => notEmpty.value !== null && notEmpty.value !== undefined)
              .map(column => {
                let value = column.value;
                switch (column.fieldId) {
                  case 'issue.duration':
                    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                    value = `${column.value} дн.`;
                    break;
                  case 'issue.loading_percent':
                    value = `${column.value} %`;
                    break;
                  case 'issue.estimated_hours':
                    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
                    value = `${column.value} ч.`;
                    break;
                  case 'issue.done_ratio':
                    value = `${column.value} %`;
                    break;
                }
                return value;
              })
              .join('; '),
            //...(column.services.predecessors.length > 0 && { parent: column.services.projectId }),
            ...(column.services.predecessors.length > 0 && {
              dependencies: predecessors,
            }),
            ...(column.services.hierarchy.length >= 2 && { parent: parentForTable.toString() }),
            /* ...(column.services.hierarchy.length != 1 && {
               dependencies: [
                 {
                   sourceId: parentForCharts,
                   sourceTarget: 'endOfTask',
                   ownTarget: 'startOfTask',
                 },
               ],
             }),*/
            loadingPercent: column.services.loadingPercent,
            progress: column.services.doneRatio,
            duration: column.services.duration,
            isParent: column.services.isParent,
            isDisabled: true,
            hide: false,
            editable: true,
            styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
          };
        },
        [ganttModalData],
      ),
    [ganttModalData],
  );
  const formattedGanttRows = useCallback(
    rows =>
      rows.reduce((acc, issue) => {
        console.log('formattedGanttRows');
        console.log(issue);
        if (issue.rowType === 'normal') {
          let path = [];

          if (issue.services.parentId) {
            path.push(issue.services.parentId);
            path.push(issue.rowId);
          } else {
            path.push(issue.rowId);
          }
          const newCols = issue.columns.reduce((columnAcc, _, i) => {
            if (issue.columns[i].fieldId == 'issue.subject') {
              issue.columns[i].fieldType = 'issue.subject';
            }
            switch (issue.columns[i].fieldId) {
              case 'issue.start':
              case 'issue.end':
                let issueDate = issue.columns[i].value;
                if (issue.type == 'project') {
                  issueDate = dayjs(issueDate).toISOString();
                }
                return {
                  ...columnAcc,
                  [issue.columns[i].fieldId]: issueDate,
                };
              default:
                return {
                  ...columnAcc,
                  [issue.columns[i].fieldId]: issue.columns[i].value,
                };
            }
          }, {});
          return [
            ...acc,
            {
              id: issue.rowId,
              kind: issue.type,
              project: Number(issue.services.projectId),
              ['issue.gantt_id']: '#' + issue.rowId,
              path: issue.services.hierarchy.map(item => item.toString()),
              ...newCols,
              level: issue.services.level,
              services: issue.services,
              editable: false,
              rowName: issue.rowName,
              rowId: issue.rowId,
              type: issue.type,
              signatures: issue.signatures,
            },
          ];
        }
        return [];
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  useEffect(() => {
    let ignore = false;
    async function getGanttFiltersData() {
      const response = await getGantFilters(query.project);
      if (!ignore) {
        return response;
      }
    }

    getGanttFiltersData()
      .then(res => {
        const { data: ganttFiltersData } = res;
        const data = {};
        ganttFiltersData.fields && (data.fields = ganttFiltersData.fields);
        ganttFiltersData.filters && (data.filters = ganttFiltersData.filters);
        ganttFiltersData.planTypes && (data.planTypes = ganttFiltersData.planTypes);
        ganttFiltersData.signatures && (data.signatures = ganttFiltersData.signatures);
        setGanttModalData(data);
      })
      .catch(error => handleError(error.response));
  }, [handleError, query.project, setQuery]);

  useEffect(() => {
    if (ganttModalData) {
      if (ganttModalData.filters) {
        const checkedFilters = ganttModalData.filters.filter(item => item.isChecked);
        const formattedFilters = checkedFilters.map(item => {
          const formattedItem = item;
          item.options = item.values ? item.values : [];
          delete item.values;
          return formattedItem;
        });
        setSelectedFilters(formattedFilters);
      }
      ganttModalData.signatures && setSelectedSignatures(ganttModalData.signatures.filter(item => item.isChecked));
      ganttModalData.planTypes && setSelectedPlanType(ganttModalData.planTypes.find(item => item.isChecked));
    }
  }, [ganttModalData]);

  const handleAcceptGanntSave = useCallback(() => {
    setGanttSavingLoader(true);
    if (socket) {
      socket.emit('saveGantt', {
        token: accessToken,
        projectId: Number(query.project),
        saveSettings: { saveType: gantErrorValue },
      });
    }
  }, [accessToken, gantErrorValue, query.project, socket]);

  const handleSaveAfterError = useCallback(() => {
    handleAcceptGanntSave();
    setShowSavingError(false);
  }, [handleAcceptGanntSave]);

  useEffect(() => {
    if (socket) {
      let tempData = {};
      socket.on('connect', () => {
        //console.log('Connected');
      });

      socket.on('startGetGanttTable', () => {
        //console.log('startGetGanttTable');
      });
      socket.on('ganttColumns', data => {
        //console.log('ganttColumns', data);
        tempData.ganttColumns = data;
      });
      socket.on('ganttSignatures', data => {
        //console.log('ganttSignatures', data);
        tempData.ganttSignatures = data;
      });
      socket.on('ganttRowsUpdate', data => {
        console.log('ganttRowsUpdate');
        console.log(data);
        console.log(formattedGanttRows(data));
        let rightSideData = formattedRightSideData(data);
        setGanttRightSideData(prevState => {
          rightSideData.forEach(row => {
            const index = prevState.findIndex(item => item.id === row.id);
            if (index > -1) {
              prevState[index] = row;
            }
          });
          return prevState;
        });
        setRightGantState(prevState => {
          return prevState + 1;
        });
        setRowsToUpdate(formattedGanttRows(data));
        setDisableSaveButton(false);
      });
      socket.on('ganttRowUpdate', data => {
        let rightSideData = formattedRightSideData([data]);
        setGanttRightSideData(prevState => {
          rightSideData.forEach(row => {
            const index = prevState.findIndex(item => item.id === row.id);
            if (index > -1) {
              prevState[index] = row;
            }
          });
          return prevState;
        });
        console.log('ganttRowUpdate');
        console.log(formattedGanttRows([data]));
        setRowsToUpdate(formattedGanttRows([data]));
        setDisableSaveButton(false);
        setRightGantState(prevState => {
          return prevState + 1;
        });
      });
      socket.on('endUpdateGanttTable', data => {
        //console.log('endUpdateGanttTable', data);
      });
      socket.on('ganttGroup', data => {
        //console.log('ganttGroup', data);
        tempData.ganttGroup = data;
      });
      socket.on('saveGanttModal', data => {
        console.log('saveGanttModal');
        console.log(date);
        //setGanttSavingLoader(true);
      });
      socket.on('endSaveGanttTable', () => {
        setGanttSavingLoader(false);
        setGanttErrorValue('save_all');
        if (ganttRowsErrors.length === 0) {
          setIsLoading(true);
          setDisableSaveButton(true);
        }
      });
      socket.on('endSaveGanttModal', data => {
        setGanttSavingLoader(false);
        setDisableSaveButton(false);
      });
      socket.on('checkSaveGanttError', data => {
        setGanttSavingLoader(false);
        if (data.length !== 0) {
          setShowSavingError(true);
        } else {
          handleAcceptGanntSave();
        }
      });
      socket.on('ganttMovingIssue', () => {
        //setGanttSavingLoader(true);
      });
      socket.on('endGanttMovingIssue', () => {
        setGanttSavingLoader(false);
      });
      socket.on('ganttUpdateHierarchy', data => {
        setGanttRightSideData(formattedRightSideData(data));
        setGanttTableRowData(formattedGanttRows(data));
        setRightGantState(prevState => {
          return prevState + 1;
        });
        setDisableSaveButton(false);
      });
      socket.on('endGetGanttTable', () => {
        setGanttTableData(tempData);
        //console.log(formattedRightSideData(tempData.ganttGroup));
        setGanttRightSideData(formattedRightSideData(tempData.ganttGroup));
        setGanttTableColumnsData(formattedGanttColumns(tempData.ganttColumns));
        setGanttTableRowData(formattedGanttRows(tempData.ganttGroup));
        setIsLoading(false);
      });
      socket.on('ganttSaveErrors', data => {
        setGanttRowsErrors(data.errors);
      });
      socket.on('endDateAfterStartDateError', data => {
        setGanttDateErrors(data);
      });
      socket.on('access_denied_error', () => {
        handleError({ status: '403' });
      });
    }
    return () => {
      if (socket) {
        socket.off('connect');
        socket.off('startGetGanttTable');
        socket.off('ganttColumns');
        socket.off('ganttSignatures');
        socket.off('ganttRowsUpdate');
        socket.off('endUpdateGanttTable');
        socket.off('ganttGroup');
        socket.off('saveGanttModal');
        socket.off('endSaveGanttTable');
        socket.off('endSaveGanttModal');
        socket.off('checkSaveGanttError');
        socket.off('ganttMovingIssue');
        socket.off('endGanttMovingIssue');
        socket.off('ganttUpdateHierarchy');
        socket.off('endGetGanttTable');
        socket.off('ganttSaveErrors');
        socket.off('access_denied_error');
      }
    };
  }, [
    formattedGanttColumns,
    formattedRightSideData,
    formattedGanttRows,
    handleAcceptGanntSave,
    socket,
    ganttRowsErrors.length,
    handleError,
  ]);

  const handleGanttIssueModalOpen = useCallback(() => {
    setOpenGantIssueModal(true);
  }, []);

  const handleTableViewMode = useCallback(
    operation => {
      setGanttTableViewMode(prev => {
        let indexEnum;
        if (operation == 'Up') {
          if (prev == 5) {
            indexEnum = prev;
          } else {
            indexEnum = prev + 1;
          }
        } else if (operation == 'Down') {
          if (prev == 0) {
            indexEnum = prev;
          } else {
            indexEnum = prev - 1;
          }
        }
        setGanttTableViewModeEnum(ViewModeEnum[indexEnum]);
        return indexEnum;
      });
    },
    [setGanttTableViewModeEnum, ViewModeEnum],
  );

  const handleTableViewModeClear = useCallback(() => {
    setGanttTableViewMode(0);
    setGanttTableViewModeEnum(ViewModeEnum['0']);
  }, [setGanttTableViewModeEnum, ViewModeEnum]);

  function CustomGridTreeDataGroupingCell(props) {
    const { id, field, row, rowNode } = props;
    const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
    const handleClick = () => {
      if (rowNode.type !== 'group') {
        return;
      }
      setFirstLoadGroup(false);
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      setGanttHideTask({ id: id, hide: rowNode.childrenExpanded });
      /* console.log('ganttTableRowData');
            setGanttRightSideData(prevState => {
        const index = prevState.findIndex(item => item.id === id);
        if (index > -1) {
          prevState[index].hideChildren = rowNode.childrenExpanded;
        }
        return prevState;
      });
      setRightGantState(prevState => {
        return prevState + 1;
      });
      console.log(ganttTableRowData);
      let tasksToHide = ganttTableRowData.find(row => row.id === id).services.childrenIds;
      let newDataForRightSide = ganttTableRowData.filter(
        object => !tasksToHide.some(toDelete => toDelete.toString() === object.id),
      );
      console.log(tasksToHide);
      console.log(newDataForRightSide);
      console.log(formattedRightSideData(newDataForRightSide));
      setGanttRightSideData(formattedRightSideData(newDataForRightSide)); */
      //onExpanderClick(row.id, rowNode.childrenExpanded);
      // setGanttTableShowChilds(true);
      /* setSortedTasks(prev => {
         return prev.map(task => {
           if (row.id === task.id) {
             return { ...task, hideChildren: rowNode.childrenExpanded };
           }
           return task;
         });
       });
      // setDatagridState(apiRef.current.state);
      //apiRef.current.setCellFocus(id, field);

      // !!  setDatagridState(apiRef.current.state);
       */
    };
    return (
      <Box marginLeft={rowNode.depth * 3} overflow={'hidden'}>
        {filteredDescendantCount > 0 ? (
          <Button
            className={classes.treeButton}
            sx={{
              minWidth: 'auto',
              padding: 0,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              gap: '5px',
              backgroundColor: 'initial !important',
              color: '#212346',
            }}
            onClick={() => handleClick()}
            tabIndex={-1}
            size="small"
          >
            {rowNode.childrenExpanded ? (
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.66675 6.6665L8.00008 9.99984L11.3334 6.6665H4.66675Z" fill="#7174AC" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                <path fill="#7174AC" d="M6.667 11.333L10 8 6.667 4.667v6.666z"></path>
              </svg>
            )}
            {row.kind === 'project' && (
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                <path
                  fill="#7174AC"
                  d="M13.334 4H8L6.667 2.667h-4c-.733 0-1.327.6-1.327 1.333l-.007 8c0 .733.6 1.333 1.334 1.333h10.666c.734 0 1.334-.6 1.334-1.333V5.333c0-.733-.6-1.333-1.333-1.333zm-4 6.667H4V9.333h5.334v1.334zM12 8H4V6.667h8V8z"
                ></path>
              </svg>
            )}
            {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
            <Tooltip title={'Название'} PopperProps={{ className: classes.commentTooltip }}>
              <Typography variant="h5" fontWeight={700}>
                {row['issue.subject']}
              </Typography>
            </Tooltip>
          </Button>
        ) : (
          // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
          <Tooltip title={'Название'} PopperProps={{ className: classes.commentTooltip }}>
            <Box display={'flex'} alignItems={'center'}>
              {row.kind === 'project' && (
                <Box display={'flex'} alignItems={'center'} margin={'0 4px'}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
                    <path
                      fill="#7174AC"
                      d="M13.334 4H8L6.667 2.667h-4c-.733 0-1.327.6-1.327 1.333l-.007 8c0 .733.6 1.333 1.334 1.333h10.666c.734 0 1.334-.6 1.334-1.333V5.333c0-.733-.6-1.333-1.333-1.333zm-4 6.667H4V9.333h5.334v1.334zM12 8H4V6.667h8V8z"
                    ></path>
                  </svg>
                </Box>
              )}
              <Typography variant="h5" fontWeight={row.kind === 'project' ? 700 : 400}>
                {row['issue.subject']}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  }
  const groupingColDef = {
    // eslint-disable-next-line no-cyrillic-string/no-cyrillic-string
    headerName: 'Название',
    renderCell: params => <CustomGridTreeDataGroupingCell {...params} />,
    cellClassName: () => 'tableCell tableGroupGanttCell',
    renderHeader: props => (
      <Typography variant="h4" fontWeight={700}>
        {props.colDef.headerName}
      </Typography>
    ),
  };
  const isGroupExpanded = useCallback(
    node => {
      if (firstLoadGroup) {
        expansionState.current[node.id] = true;
        return expansionState.current[node.id];
      } else {
        return !!expansionState.current[node.id];
      }
    },
    [expansionState, firstLoadGroup],
  );
  const handleGanttIssueModalClose = useCallback(() => {
    setOpenGantIssueModal(false);
    setEditIssueData(null);
  }, []);
  const handleGanttIssueRelatedModalOpen = useCallback(() => {
    setOpenGantIssueRelatedModal(true);
  }, []);
  const handleGanttIssueRelatedModalClose = useCallback(() => {
    setOpenGantIssueRelatedModal(false);
  }, []);
  const handleParamsModalOpen = useCallback(() => {
    setOpenParamsModal(true);
  }, []);
  const handleParamsModalClose = useCallback(() => {
    setOpenParamsModal(false);
  }, []);

  const handleGanttIssueModalSave = useCallback(
    (data, id) => {
      if (socket) {
        setGanttSavingLoader(true);
        socket.emit('saveGanttModal', {
          token: accessToken,
          groupId: query.project,
          projectId: Number(query.project),
          updatedValue: data,
          rowId: id.toString(),
        });
        setOpenGantIssueModal(false);
        setEditIssueData(null);
      }
    },
    [accessToken, query.project, socket],
  );

  const handleParamsModalSave = useCallback(
    async modalData => {
      let data = {};
      modalData.planType && (data.planType = Number(modalData.planType));
      modalData.filters &&
        (data.filters = modalData.filters.map(item => {
          return { instance: item.instance, field: item.field };
        }));
      modalData.fields.selectedFields &&
        (data.fields = modalData.fields.selectedFields.map(item => {
          return { instance: item.instance, field: item.field };
        }));
      modalData.signatures &&
        (data.signatures = modalData.signatures.map(item => {
          return { instance: item.instance, field: item.field };
        }));
      try {
        const { data: response } = await saveGanttSettings(query.project, data);
        if (response) {
          if (response.filters) {
            const checkedFilters = response.filters.filter(item => item.isChecked);
            const formattedFilters = checkedFilters.map(item => {
              const formattedItem = item;
              item.options = item.values ? item.values : [];
              delete item.values;
              return formattedItem;
            });
            setSelectedFilters(formattedFilters);
          }
          response.signatures && setSelectedSignatures(response.signatures.filter(item => item.isChecked));
          response.planTypes && setSelectedPlanType(response.planTypes.find(item => item.isChecked));
          if (response.fields) {
            setActiveFields({
              availableFields: response.fields.filter(item => !item.isChecked),
              selectedFields: response.fields.filter(item => item.isChecked),
            });
          }
          setOpenParamsModal(false);
          setIsLoading(true);
        }
      } catch (error) {
        handleError(error.response);
      }
    },
    [handleError, query.project],
  );
  const handleParamsModalClear = useCallback(() => {
    setOpenParamsModal(false);
  }, []);
  const handleSelectFilters = useCallback(
    data => {
      const pos = selectedFilters.map(e => e.fieldId).indexOf(data.fieldId);
      if (JSON.stringify(data) === JSON.stringify(selectedFilters[pos])) {
        return;
      }
      if (!data.hasOwnProperty('operation')) {
        return;
      }
      const filters = [...selectedFilters.slice(0, pos), data, ...selectedFilters.slice(pos + 1)];
      //console.log(filters);
      setSelectedFilters(filters);
      setQuery(prev => ({
        ...prev,
        filters: filtersToQuery(filters.filter(filter => filter.hasOwnProperty('values'))),
      }));
      setIsLoading(true);
    },
    [selectedFilters, setQuery],
  );

  const handleEditMenu = useCallback(() => {
    setEditIssueData({
      project: query.project,
      tracker: Number(selectedRow.services.trackerId),
      id: Number(selectedRow.id),
      name: selectedRow.rowName,
    });
    handleGanttIssueModalOpen();
    setContextMenu(null);
  }, [handleGanttIssueModalOpen, query.project, selectedRow]);
  const handleDeleteRow = useCallback(() => {
    console.log('handleDeleteRow');
    socket.emit('deleteGanttRow', {
      token: accessToken,
      rowId: Number(selectedRow.id),
      groupId: selectedRow.project.toString(),
      projectId: selectedRow.project,
    });
    setContextMenu(null);
  }, [selectedRow, accessToken, socket]);
  const handleContextMenu = event => {
    const row = ganttTableRowData.find(row => Number(row.id) === Number(event.currentTarget.getAttribute('data-id')));
    event.preventDefault();
    row !== undefined && setSelectedRow(row);
    setContextMenu(contextMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };
  const handleContextSubMenu = event => {
    event.preventDefault();
    setContextSubMenu(contextSubMenu === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null);
  };
  const handleCloseContextSubMenu = () => {
    setContextSubMenu(null);
  };
  const handlePredecessorOptions = useCallback(async (value, props) => {
    let params = {
      row: props.row.id,
      search: value,
      project: props.row.project,
    };
    try {
      const response = await getIssuePredecessors(params);
      if (response) {
        //console.log('handlePredecessorOptions');
        //console.log(handlePredecessorOptions);
        setPredecessors(prevState => {
          prevState[props.row.id] = response.data;
          //console.log('setPredecessors');
          //console.log(prevState);
          return prevState;
        });
      }
    } catch (error) {
      console.error('ERROR WITH GET', error);
    }
  }, []);
  const handleMovingIssueChange = useCallback(
    async value => {
      let params = {
        search: value,
      };
      selectedRow && (params.row = selectedRow.id);
      query.project && (params.project = query.project);
      try {
        const response = await getGanttMovingIssue(params);
        if (response) {
          setMovingIssueOptions(response.data);
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [query.project, selectedRow],
  );
  const handleCellClick = useCallback(params => {
    //console.log(params);
  }, []);

  const handleSaveGantt = useCallback(() => {
    setGanttSavingLoader(true);
    setGanttRowsErrors([]);
    if (socket) {
      socket.emit('checkGanttIssuesUpdate', {
        token: accessToken,
        projectId: Number(query.project),
        //saveSettings: { projectId: Number(query.project), saveType: 'save_all' },
      });
    }
  }, [accessToken, query.project, socket]);

  const handleClearGantt = useCallback(
    async value => {
      try {
        const response = await clearGanttSave(query.project);
        if (response) {
          setIsLoading(true);
        }
      } catch (error) {
        console.error('ERROR WITH GET', error);
      }
    },
    [query.project],
  );
  const handleMovingIssueSelect = useCallback(
    value => {
      if (socket && selectedRow) {
        setGanttSavingLoader(true);
        socket.emit('ganttMovingIssue', {
          token: accessToken,
          projectId: query.project,
          rowId: selectedRow.id,
          afterId: value.valueId.toString(),
        });
      }
      setContextSubMenu(null);
      setContextMenu(null);
      setMovingIssueOptions([]);
    },
    [accessToken, query.project, selectedRow, socket],
  );
  React.useEffect(() => {
    if (!isLoading) {
      apiRef.current?.subscribeEvent('rowExpansionChange', node => {
        console.log('rowExpansionChange');
        console.log(expansionState);
        console.log(expansionState.current[node.id]);
        console.log(node);
        expansionState.current[node.id] = node.childrenExpanded;
      });
    }
  }, [apiRef, isLoading]);

  const downloadCsv = useCallback(
    e => {
      async function downloadGanttCsv() {
        try {
          setDownloadLoading(true);
          const params = { project: Number(query.project) };
          if (params) {
            const { data } = await getGantCsv(params);
            const url = window.URL.createObjectURL(new Blob(['\ufeff', data], { type: 'text/csv;charset=utf-8;' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `gantt-${dayjs().format('YYYY-MM-DD')}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setDownloadLoading(false);
        }
      }
      downloadGanttCsv();
    },
    [query],
  );

  return (
    <>
      {!isLoading ? (
        <Page
          title={pageTitle}
          width="100%"
          sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
        >
          {ganttSavingLoader && (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              zIndex={99}
              position={'fixed'}
            >
              <CircularProgress color="secondary" />
            </Grid>
          )}
          <GanttIssueModal
            socket={socket}
            token={accessToken}
            data={editIssueData}
            open={openGantIssueModal}
            handleClose={handleGanttIssueModalClose}
            handleSave={handleGanttIssueModalSave}
          ></GanttIssueModal>
          <GanttIssueRelatedModal
            open={openGantIssueRelatedModal}
            handleClose={handleGanttIssueRelatedModalClose}
            //handleSave={handleGanttIssueModalSave}
          ></GanttIssueRelatedModal>
          {ganttModalData && (
            <GridFiltersModal
              selectedFilters={selectedFilters}
              activeFields={activeFields}
              selectedSignatures={selectedSignatures}
              selectedPlanType={selectedPlanType}
              handleSave={data => handleParamsModalSave(data)}
              //handleClear={data => handleParamsModalClear(data)}
              handleClose={handleParamsModalClose}
              open={openParamsModal}
              data={ganttModalData}
            />
          )}
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography m={0} padding={'10px 0'} variant="h1">
                {pageTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems={'center'}>
                {/*                <ToggleButtonGroup
                  value={ganttTableShowChilds}
                  className={ganttStyle.groupByFilter}
                  exclusive
                  onChange={e => {
                    setGanttTableShowChilds(e.target.value);
                  }}
                >
                  <ToggleButton value={true} className={ganttStyle.resPlanToggle}>
                    <FormattedMessage id="gantt_show_all" />
                  </ToggleButton>
                  <ToggleButton value={false} className={ganttStyle.resPlanToggle}>
                    <FormattedMessage id="gantt_hide_all" />
                  </ToggleButton>
                </ToggleButtonGroup>*/}
                <Button
                  sx={{ height: '40px', marginLeft: '10px' }}
                  variant="defaultGreyPrimary"
                  disableRipple
                  onClick={handleGanttIssueModalOpen}
                >
                  <Typography variant="h4">
                    <FormattedMessage id="issues_add" />
                  </Typography>
                </Button>
                <Box display="flex" alignItems="center" marginLeft={'10px'}>
                  <Button
                    disabled={disableSaveButton}
                    onClick={handleSaveGantt}
                    disableRipple
                    className={ganttStyle.cutButton}
                    variant="cutGreyPrimary"
                    sx={{ height: '40px', marginRight: '2px', padding: '8px 20px' }}
                  >
                    <FormattedMessage id="save_text" defaultMessage="Save" />
                  </Button>
                  <Button
                    onClick={handleClearGantt}
                    disableRipple
                    variant="cutGreySecondary"
                    sx={{ height: '40px', fontSize: '12px', padding: '8px 10px' }}
                  >
                    <FormattedMessage id="spreadsheet_table_clear" defaultMessage="Clear" />
                  </Button>
                </Box>
                <Button
                  variant="defaultGreyPrimaryWithIcon"
                  disableRipple
                  onClick={handleParamsModalOpen}
                  sx={{ height: '40px', marginLeft: '10px' }}
                >
                  <Box display="flex" alignItems="center" marginRight={'4px'}>
                    <FilterIcon width="20" height="20" viewBox="0 0 20 20" />
                  </Box>
                  <Typography variant="h4" fontWeight={600}>
                    <FormattedMessage id="parameters_text" />
                  </Typography>
                </Button>
                {!downloadLoading ? (
                  <IconButton onClick={e => downloadCsv(e)} disableRipple sx={{ padding: '0', marginLeft: '4px' }}>
                    <DownloadButton width="24" height="24" viewBox="0 0 24 24" />
                  </IconButton>
                ) : (
                  <Box marginLeft={'4px'}>
                    <CircularProgress size={24} color="secondary" />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
            marginTop={'10px'}
            marginBottom={'20px'}
            sx={{ cursor: 'pointer' }}
          >
            <Grid item>
              <IconButton onClick={() => handleTableViewMode('Down')} sx={{ padding: '0', cursor: 'pointer' }}>
                <ZoomInIcon />
              </IconButton>
              {ganttTableViewModeEnum != 'Year' ? (
                <IconButton
                  onClick={() => handleTableViewMode('Up')}
                  sx={{ padding: '0', cursor: 'pointer', marginLeft: '4px' }}
                >
                  <ZoomOutIcon />
                </IconButton>
              ) : null}
              {ganttTableViewModeEnum == 'Year' ? (
                <IconButton
                  onClick={() => handleTableViewModeClear()}
                  sx={{ padding: '0', cursor: 'pointer', marginLeft: '4px' }}
                >
                  <ZoomCancelIcon />
                </IconButton>
              ) : null}
            </Grid>
          </Grid>
          {selectedFilters.length > 0 && (
            <GridFiltersPanel data={selectedFilters} handleCloseFilter={handleSelectFilters}></GridFiltersPanel>
          )}
          {showSavingError && (
            <Grid container direction="column" marginBottom={2}>
              {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
              <Typography variant="h3" fontWeight={600} color="#E03737">
                Произошла ошибка
              </Typography>
              <Grid container marginLeft={4} wrap="nowrap" direction="column" spacing={0}>
                <RadioGroup
                  value={gantErrorValue}
                  onChange={e => setGanttErrorValue(e.target.value)}
                  defaultValue={'save_all'}
                >
                  <FormControlLabel
                    value="save_all"
                    control={
                      <Radio
                        disableRipple
                        icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                        checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                      />
                    }
                    label={intl.formatMessage({ id: 'saving_gantt' })}
                  />
                  <FormControlLabel
                    value="cancel_changes"
                    control={
                      <Radio
                        disableRipple
                        icon={<UncheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                        checkedIcon={<CheckedRadioIcon width="16" height="16" viewBox="0 0 16 16" />}
                      />
                    }
                    label={intl.formatMessage({ id: 'clearing_gantt' })}
                  />
                </RadioGroup>
                <Grid>
                  <Button variant="defaultBluePrimary" disableRipple onClick={handleSaveAfterError}>
                    <Typography variant="h4" fontWeight={600}>
                      <FormattedMessage id="resPlan_accept_saving" />
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {ganttRowsErrors.length > 0 &&
            ganttRowsErrors.map((item, index) => (
              <Box marginTop={index > 0 ? '10px' : 0}>
                <Typography variant="h3" fontWeight={600} color="#E03737">
                  {`#${item.id}: ${item.message}`}
                </Typography>
              </Box>
            ))}
          {ganttDateErrors && (
            <Box>
              <Typography variant="h3" color={'#E03737'}>
                {`#${ganttDateErrors.rowId}: ${intl.formatMessage({ id: 'gantt_issue_modal_date_error' })}`}
              </Typography>
            </Box>
          )}
          <Grid
            container
            flexDirection={'column'}
            flex={1}
            height={'100%'}
            overflow={'hidden'}
            position={'relative'}
            borderRadius={'16px'}
          >
            <Grid container className={clsx(ganttStyle.ganttHolder, 'flex grow overflow-clip relative')}>
              <Box style={{ width: `${width}%` }}>
                <DataGridPro
                  isGroupExpandedByDefault={isGroupExpanded}
                  onCellClick={props => handleCellClick(props)}
                  className={clsx(
                    dataGridTableStyle.DataGridTableRoot,
                    dataGridTableStyle.FixedHeaderDataGridTableRoot,
                  )}
                  ref={dataGridProRef}
                  rows={ganttTableRowData}
                  columns={ganttTableColumnsData}
                  apiRef={apiRef}
                  loading={ganttTableRowData.length === 0}
                  rowHeight={40}
                  //checkboxSelection
                  groupingColDef={groupingColDef}
                  hideFooter
                  getTreeDataPath={row => row['path']}
                  disableColumnFilter
                  disableRowSelectionOnClick
                  disableColumnMenu
                  treeData
                  isCellEditable={params => params.row.kind != 'project'}
                  getRowClassName={params => params.row.services.isError && 'errorRow'}
                  sx={{
                    '& .MuiDataGrid-row': { marginTop: 1, marginBottom: 1, margin: 0 },
                    '& .coloured': { textAlign: 'center', color: '#7181AD' },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' },
                  }}
                  slotProps={{
                    row: {
                      onContextMenu: handleContextMenu,
                      style: { cursor: 'context-menu' },
                    },
                  }}
                />
                <Menu
                  sx={{
                    '& .MuiPaper-root': {
                      border: '1px solid #E4E4EF',
                      boxShadow: '0px 5px 10px -8px #A0A1AA66',
                      borderRadius: '16px',
                    },
                    '& .MuiList-root': { padding: 0 },
                  }}
                  open={contextMenu !== null}
                  onClose={handleCloseContextMenu}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                  }
                  slotProps={{
                    root: {
                      onContextMenu: event => {
                        event.preventDefault();
                        handleClose();
                      },
                    },
                  }}
                >
                  {selectedRow && selectedRow.services.canEdit && (
                    <MenuItem sx={{ fontSize: '12px', color: '#212346', lineHeight: '20px' }} onClick={handleEditMenu}>
                      {intl.formatMessage({ id: 'context_menu_edit' })}
                    </MenuItem>
                  )}
                  {selectedRow && selectedRow.services.isNew && (
                    <MenuItem sx={{ fontSize: '12px', color: '#212346', lineHeight: '20px' }} onClick={handleDeleteRow}>
                      {intl.formatMessage({ id: 'context_menu_delete' })}
                    </MenuItem>
                  )}
                  <MenuItem
                    sx={{ fontSize: '12px', color: '#212346', lineHeight: '20px' }}
                    onClick={handleContextSubMenu}
                  >
                    {intl.formatMessage({ id: 'context_menu_move' })}
                  </MenuItem>
                </Menu>
                <Menu
                  sx={{
                    '& .MuiPaper-root': {
                      border: '1px solid #E4E4EF',
                      boxShadow: '0px 5px 10px -8px #A0A1AA66',
                      borderRadius: '16px',
                    },
                    '& .MuiList-root': { padding: 0 },
                  }}
                  open={contextSubMenu !== null}
                  onClose={handleCloseContextSubMenu}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    contextSubMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                  }
                  slotProps={{
                    root: {
                      onContextMenu: event => {
                        event.preventDefault();
                        handleClose();
                      },
                    },
                  }}
                >
                  <MenuItem
                    sx={{
                      fontSize: '12px',
                      color: '#212346',
                      lineHeight: '20px',
                      '&:hover': { backgroundColor: '#fff' },
                      '&.Mui-focusVisible': { backgroundColor: '#fff' },
                    }}
                  >
                    <Grid container direction={'column'}>
                      <Grid item>{intl.formatMessage({ id: 'context_menu_after' })}</Grid>
                      <Grid item>
                        <Autocomplete
                          noOptionsText={intl.formatMessage({ id: 'not_found' })}
                          filterOptions={x => x}
                          onChange={(event, newValue) => handleMovingIssueSelect(newValue)}
                          className={issueCreateEditClasses.filterSelect}
                          disablePortal
                          options={movingIssueOptions}
                          sx={{ width: 253 }}
                          disableClearable
                          renderOption={(props, option) => {
                            return (
                              <MenuItem {...props} key={option.valueId} value={option.valueId}>
                                {option.valueName}
                              </MenuItem>
                            );
                          }}
                          getOptionLabel={option => option.valueName || ''}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          PopperComponent={props => (
                            <Popper
                              {...props}
                              sx={{ transform: 'none!important' }}
                              popperOptions={{ strategy: 'relative' }}
                              placement="bottom-start"
                            ></Popper>
                          )}
                          renderInput={params => (
                            <TextField
                              {...params}
                              onChange={ev => handleMovingIssueChange(ev.target.value)}
                              placeholder={intl.formatMessage({ id: 'choose' })}
                            />
                          )}
                          popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                        />
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Menu>
                <div
                  draggable
                  onDrag={dragHandler}
                  className="flex justify-center items-center p-1 h-full bg-slate-800 cursor-col-resize"
                  style={{
                    position: 'absolute',
                    left: `${width}%`,
                    top: 0,
                    height: '100%',
                    width: '3px',
                    background: '#e4e4ef',
                    cursor: 'col-resize',
                    zIndex: 2,
                  }}
                >
                  <div className="w-1 border-x border-white h-1/6" />
                </div>
              </Box>

              <Box style={{ width: `${100 - width}%`, zIndex: 1 }}>
                <Gantt
                  ganttTableShowChilds={ganttTableShowChilds}
                  ganttHideTask={ganttHideTask}
                  setGanttTableShowChilds={setGanttTableShowChilds}
                  setGanttHideTask={setGanttHideTask}
                  comparisonLevels={1}
                  className={classes.ganttTableWrap}
                  columnsTable={ganttTableColumnsData}
                  rowsTable={ganttTableRowData}
                  tasks={ganttRightSideData}
                  viewMode={ganttTableViewModeEnum}
                  key={rightGantState}
                />
              </Box>
            </Grid>
          </Grid>
        </Page>
      ) : (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress color="secondary" />
        </Grid>
      )}
    </>
  );
};

export default GanttContainer;
