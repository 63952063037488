import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import userPageStyles from '../userPage/UserPage.styles';
import {
  getGanttAccessRoles,
  getTimesheetsSettings,
  saveGanttAccessRoles,
  saveTimesheetsSettings,
} from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import ChevronIcon from '../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import CheckboxIcon from '../../../../packages/common/shared-ui/src/icons/CheckboxIcon';
import CheckboxIconChecked from '../../../../packages/common/shared-ui/src/icons/CheckboxIconChecked';
import issueFormFieldStyles from '../../issueCreateEdit/components/IssueFormField.styles';
import DeleteIcon from '../../../../packages/common/shared-ui/src/icons/DeleteIcon';
import clsx from 'clsx';
import ganttSettingsStyles from '../ganttSettings/GanttSettings.styles';

const TimesheetsSettings = ({ intl }) => {
  const pageTitle = intl.formatMessage({ id: 'timesheet_settings_page_title' });
  const commonClasses = userPageStyles();
  const fieldsClasses = ganttSettingsStyles();
  const navigate = useNavigate();
  const [timesheetsSettingsFields, setTimesheetsSettingsFields] = useState([]);
  const [initialTimesheetsSettingsFields, setInitialTimesheetsSettingsFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saveSettingsloading, setSaveSettingsloading] = useState(false);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const handleIssueError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );

  useEffect(() => {
    let ignore = false;
    async function getSettings() {
      const { data: settings } = await getTimesheetsSettings();
      if (!ignore) {
        return settings;
      }
    }
    getSettings()
      .then(res => {
        setTimesheetsSettingsFields(res.standards);
        setInitialTimesheetsSettingsFields(res.standards);
        setLoading(false);
      })
      .catch(error => handleIssueError(error.response));
    return () => {
      ignore = true;
    };
  }, [handleIssueError]);

  const saveConfirmationSettings = useCallback(async () => {
    setSaveSettingsloading(true);
    const data = timesheetsSettingsFields
      .filter(field => field.changed)
      .reduce((acc, item) => {
        return { ...acc, [item.fieldId]: item.valueId.map(item => Number(item.valueId)) };
      }, {});
    try {
      const { data: settings } = await saveTimesheetsSettings(data);
      if (settings) {
        setTimesheetsSettingsFields(settings.standards);
        setSaveSettingsloading(false);
        setSettingsChanged(false);
      }
    } catch (error) {
      setSaveSettingsloading(false);
      handleIssueError(error.response);
    }
  }, [handleIssueError, timesheetsSettingsFields]);

  const handleCancel = useCallback(() => {
    setTimesheetsSettingsFields(initialTimesheetsSettingsFields);
    setSettingsChanged(false);
  }, [initialTimesheetsSettingsFields]);

  const handleField = useCallback(
    (data, field) => {
      setSettingsChanged(true);
      let updatedObject;
      const pos = timesheetsSettingsFields.map(e => e.fieldId).indexOf(field.fieldId);
      updatedObject = {
        ...timesheetsSettingsFields[pos],
        valueId: data,
        valueName: data,
        changed: true,
      };
      const newFields = [
        ...timesheetsSettingsFields.slice(0, pos),
        updatedObject,
        ...timesheetsSettingsFields.slice(pos + 1),
      ];
      setTimesheetsSettingsFields(newFields);
    },
    [timesheetsSettingsFields],
  );

  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page title={pageTitle} width="100%">
          <Grid container alignItems={'center'} justifyContent={'space-between'} position={'relative'}>
            {saveSettingsloading && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <Grid item>
              <Typography m={0} padding={'10px 0'} variant="h1">
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                {`Настройка учета рабочего времени`}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="defaultGreyPrimary"
                disableRipple
                onClick={saveConfirmationSettings}
                disabled={!settingsChanged}
              >
                <Typography variant="h4" fontWeight={700}>
                  <FormattedMessage id="save_text" />
                </Typography>
              </Button>
              <Button onClick={handleCancel} variant="defaultGreyPrimary" disableRipple sx={{ marginLeft: '8px' }}>
                <Typography variant="h4" fontWeight={700}>
                  <FormattedMessage id="cancel" />
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={5} paddingTop={'20px'} marginBottom={'20px'}>
            {timesheetsSettingsFields.map((field, index) => (
              <Grid key={index} item md={6}>
                <Box className={commonClasses.blockWrapper} padding={'20px'}>
                  <FormControl
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <InputLabel className={fieldsClasses.selectLabel} sx={{ width: '100%!important' }}>
                      <Typography variant="h5" color={'#41424E'}>
                        {`${intl.formatMessage({
                          id: `timesheets_settings_${field.fieldName.replaceAll(' ', '_').toLowerCase()}`,
                        })}`}
                      </Typography>
                    </InputLabel>
                    <Autocomplete
                      multiple
                      onChange={(event, newValue) => handleField(newValue, field)}
                      className={clsx(fieldsClasses.filterSelect, fieldsClasses.filterSelectMultiple)}
                      noOptionsText={intl.formatMessage({ id: 'not_found' })}
                      options={field.fieldDefinition.values}
                      getOptionLabel={option => option.valueName || ''}
                      disableClearable
                      disablePortal
                      value={
                        !field.changed && field.fieldDefinition.values.filter(item => item.isChecked).length > 0
                          ? field.fieldDefinition.values.filter(item => item.isChecked)
                          : field.valueName
                          ? field.valueName
                          : []
                      }
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            deleteIcon={<DeleteIcon width={10} height={10} viewBox="0 0 10 10" />}
                            className={fieldsClasses.chip}
                            variant="outlined"
                            label={option.valueName}
                          />
                        ))
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props} key={option.valueId}>
                          <Checkbox
                            icon=<CheckboxIcon width="12" height="12" viewBox="0 0 12 12" />
                            checkedIcon=<CheckboxIconChecked width="12" height="12" viewBox="0 0 12 12" />
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.valueName}
                        </li>
                      )}
                      renderInput={params => (
                        <TextField {...params} placeholder={intl.formatMessage({ id: 'enter' })} />
                      )}
                      PopperComponent={props => (
                        <Popper {...props} sx={{ maxWidth: '100%!important' }} placement="bottom-start"></Popper>
                      )}
                      popupIcon={<ChevronIcon direction="down"></ChevronIcon>}
                    />
                  </FormControl>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Page>
      )}
    </>
  );
};
export default injectIntl(TimesheetsSettings);
