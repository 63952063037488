import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Grid, IconButton, MenuItem, Popper, TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import ChevronIcon from '../../../../../packages/common/shared-ui/src/icons/ChevronIcon';
import { injectIntl } from 'react-intl';
import dataGridTableStyles from '../../../../../packages/common/shared-ui/src/styles/DataGridTable.styles';
import PlusIcon from '../../../../../packages/common/shared-ui/src/icons/PlusIcon';
import SubmitIcon from '../../../../../packages/common/shared-ui/src/icons/SubmitIcon';
import DeleteIconBig from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBig';
import DeleteIconBigBlue from '../../../../../packages/common/shared-ui/src/icons/DeleteIconBigBlue';

const ObjectsHierarchyColumnItem = ({ item, intl, options, handleAction, relation, index }) => {
  const ObjectActions = { SAVE: 'save', DELETE: 'delete' };
  const dataGridTableStyle = dataGridTableStyles();

  const [currentItem, setCurrentItem] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    if (item) {
      setCurrentItem(item);
    }
  }, [item]);

  useEffect(() => {
    if (options) {
      setCurrentOptions(options);
    }
  }, [options]);

  const handleSelectObject = useCallback(
    object => {
      setSelectedObject(object);
      setCurrentItem({ ...currentItem, ...object });
    },
    [currentItem],
  );

  const handleObjectAction = useCallback(
    type => {
      handleAction(currentItem, type, relation, index);
    },
    [currentItem, handleAction, index, relation],
  );

  return (
    <>
      {currentItem && (
        <Grid item borderBottom={'1px solid #E4E4EF'}>
          <Grid container>
            <Grid item flex={1}>
              {currentItem.new ? (
                <Autocomplete
                  noOptionsText={intl.formatMessage({ id: 'not_found' })}
                  onChange={(event, newValue) => handleSelectObject(newValue)}
                  className={clsx(dataGridTableStyle.rowAutocomlpete, dataGridTableStyle.rowAutocomlpetePadding)}
                  //disablePortal
                  filterOptions={x => x}
                  options={currentOptions}
                  disableClearable
                  renderOption={(props, option) => {
                    return (
                      <MenuItem {...props} key={option.valueId} value={option.valueId}>
                        {option.valueName}
                      </MenuItem>
                    );
                  }}
                  value={currentItem ? currentItem : ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  getOptionLabel={option => option.valueName || ''}
                  PopperComponent={props => (
                    <Popper
                      {...props}
                      className={dataGridTableStyle.rowAutocomlpetePopper}
                      placement="bottom-start"
                    ></Popper>
                  )}
                  renderInput={params => <TextField {...params} placeholder={intl.formatMessage({ id: 'choose' })} />}
                  popupIcon={<ChevronIcon direction={'down'} />}
                />
              ) : (
                <Box padding={'10px'}>
                  <Typography variant="h4" color={'#082253'}>
                    {currentItem.valueName}
                  </Typography>
                </Box>
              )}
            </Grid>
            {currentItem.new && (
              <IconButton
                disabled={!currentItem.valueId}
                onClick={() => handleObjectAction(ObjectActions.SAVE)}
                sx={{ padding: '0 8px', borderLeft: '1px solid #E4E4EF', borderRadius: 0 }}
              >
                <SubmitIcon />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleObjectAction(ObjectActions.DELETE)}
              sx={{ padding: '0 8px', borderLeft: '1px solid #E4E4EF', borderRadius: 0 }}
            >
              <DeleteIconBigBlue />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default injectIntl(ObjectsHierarchyColumnItem);
