import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Page from '../../../../packages/common/shared-ui/src/components/Page';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import objectsHierarchyStyles from './ObjectsHierarchy.styles';
import PlusIcon from '../../../../packages/common/shared-ui/src/icons/PlusIcon';
import {
  deleteHierarchyObject,
  getHierarchyMasterObjects,
  getHierarchyObjectsById,
  saveHierarchyObject,
} from '../../../../packages/common/api';
import { useNavigate } from 'react-router';
import ObjectsHierarchyColumnItem from './components/ObjectsHierarchyColumnItem';

const ObjectsHierarchy = ({ intl }) => {
  const ColumnTypes = { PARENT: 'parent', CHILD: 'child' };
  const ObjectActions = { SAVE: 'save', DELETE: 'delete' };
  const styles = objectsHierarchyStyles();
  const pageTitle = intl.formatMessage({ id: 'projects_hierarchy_page_title' });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [disableAddParentObject, setDisableAddParentObject] = useState(true);
  const [disableAddChildObject, setDisableAddChildObject] = useState(true);
  const [masterObjects, setMasterObjects] = useState([]);
  const [parentObjects, setParentObjects] = useState([]);
  const [childObjects, setChildObjects] = useState([]);
  const [parentObjectsOptions, setParentObjectsOptions] = useState([]);
  const [childObjectsOptions, setChildObjectsOptions] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const handleError = useCallback(
    error => {
      if (error) {
        navigate(`/errors/error-${error.status}`);
      }
    },
    [navigate],
  );
  const columnActions = useRef(
    new Map([
      [ObjectActions.SAVE, { response: saveHierarchyObject }],
      [ObjectActions.DELETE, { response: deleteHierarchyObject }],
    ]),
  );
  const columnTypes = useRef(
    new Map([
      [
        ColumnTypes.PARENT,
        { setter: setParentObjects, disableSetter: setDisableAddParentObject, items: parentObjects },
      ],
      [ColumnTypes.CHILD, { setter: setChildObjects, disableSetter: setDisableAddChildObject, items: childObjects }],
    ]),
  );

  useEffect(() => {
    let ignore = false;
    async function getMasterObjects() {
      const { data: masterObjects } = await getHierarchyMasterObjects();
      if (!ignore) {
        return masterObjects;
      }
    }
    getMasterObjects()
      .then(res => {
        setLoading(false);
        setMasterObjects(res);
      })
      .catch(error => handleError(error.response));
    return () => {
      ignore = true;
    };
  }, [handleError]);

  const setObjects = useCallback(objects => {
    if (objects.parents) {
      const checked = objects.parents.filter(item => item.isChecked);
      const available = objects.parents.filter(item => !item.isChecked);
      setDisableAddParentObject(false);
      available && available.length === 0 && setDisableAddParentObject(true);
      setParentObjectsOptions(objects.parents.filter(item => !item.isChecked));
      checked && setParentObjects(checked);
    }
    if (objects.children) {
      const checked = objects.children.filter(item => item.isChecked);
      const available = objects.children.filter(item => !item.isChecked);
      setDisableAddChildObject(false);
      available && available.length === 0 && setDisableAddChildObject(true);
      setChildObjectsOptions(objects.children.filter(item => !item.isChecked));
      checked && setChildObjects(checked);
    }
  }, []);

  const handleSelectObject = useCallback(
    async id => {
      if (id) {
        setActionLoading(true);
        setSelectedObject(id);
        setDisableAddChildObject(false);
        setDisableAddParentObject(false);
        try {
          const { data: response } = await getHierarchyObjectsById(id);
          if (response) {
            setObjects(response);
            setActionLoading(false);
          }
        } catch (error) {
          setSelectedObject(null);
          handleError(error.response);
        }
      }
    },
    [handleError, setObjects],
  );

  const handleAddColumnItem = useCallback(
    type => {
      let items = [];
      type === ColumnTypes.PARENT && (items = parentObjects);
      type === ColumnTypes.CHILD && (items = childObjects);
      columnTypes.current.get(type).setter([...items, { new: true }]);
      columnTypes.current.get(type).disableSetter(true);
    },
    [ColumnTypes.CHILD, ColumnTypes.PARENT, childObjects, parentObjects],
  );

  const handleColumnItemAction = useCallback(
    async (object, type, relation, index) => {
      if (object) {
        let params = {};
        relation === ColumnTypes.PARENT && (params.parentId = Number(object.valueId));
        relation === ColumnTypes.CHILD && (params.childId = Number(object.valueId));
        if (type === ObjectActions.DELETE && object.new) {
          let items = [];
          relation === ColumnTypes.PARENT && (items = parentObjects);
          relation === ColumnTypes.CHILD && (items = childObjects);
          items.splice(index, 1);
          columnTypes.current.get(relation).setter(items);
          columnTypes.current.get(relation).disableSetter(false);
          return;
        }
        setActionLoading(true);
        try {
          const { data: response } = await columnActions.current.get(type).response(selectedObject, params);
          if (response) {
            setObjects(response);
            setActionLoading(false);
          }
        } catch (error) {
          setActionLoading(false);
          handleError(error.response);
        }
      }
    },
    [
      ColumnTypes.CHILD,
      ColumnTypes.PARENT,
      ObjectActions.DELETE,
      childObjects,
      handleError,
      parentObjects,
      selectedObject,
      setObjects,
    ],
  );
  return (
    <>
      {loading ? (
        <Grid container alignItems="center" justifyContent="center" width="100%" height="70px">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Page
          title={pageTitle}
          width="100%"
          sx={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column', paddingBottom: '20px' }}
        >
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography m={0} padding={'10px 0'} variant="h1">
                {/* eslint-disable-next-line no-cyrillic-string/no-cyrillic-string */}
                {`Иерархия объектов`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container flexDirection={'column'} className={styles.blockWrapper} position={'relative'}>
            {actionLoading && (
              <Grid
                container
                flexDirection={'column'}
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                zIndex={99}
                position={'absolute'}
                top={0}
                left={0}
              >
                <CircularProgress color="secondary" />
              </Grid>
            )}
            <Grid container>
              <Grid item flex={1}>
                <Box className={styles.columnHeader}>
                  <Typography variant="h4" fontWeight={700} color={'#082253'}>
                    {intl.formatMessage({ id: 'objects_hierarchy_parent_object_title' })}
                  </Typography>
                </Box>
                {parentObjects &&
                  parentObjects.map((item, index) => (
                    <ObjectsHierarchyColumnItem
                      key={index}
                      index={index}
                      item={item}
                      options={parentObjectsOptions}
                      handleAction={handleColumnItemAction}
                      relation={ColumnTypes.PARENT}
                    ></ObjectsHierarchyColumnItem>
                  ))}
                <Box
                  sx={{ padding: '8px 16px', borderBottom: masterObjects.length > 1 ? '1px solid #E4E4EF' : 'none' }}
                >
                  <IconButton
                    onClick={() => handleAddColumnItem(ColumnTypes.PARENT)}
                    sx={{ padding: '0', opacity: disableAddParentObject ? '0.2' : 1 }}
                    disabled={disableAddParentObject}
                  >
                    <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item flex={1} sx={{ borderLeft: '1px solid #E4E4EF', borderRight: '1px solid #E4E4EF' }}>
                <Box className={styles.columnHeader}>
                  <Typography variant="h4" fontWeight={700} color={'#082253'}>
                    {intl.formatMessage({ id: 'objects_hierarchy_master_object_title' })}
                  </Typography>
                </Box>
                <Grid container flexDirection={'column'}>
                  {masterObjects &&
                    masterObjects.map((item, index) => (
                      <Grid
                        item
                        key={index}
                        padding={'10px'}
                        borderBottom={'1px solid #E4E4EF'}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: selectedObject === item.redmineId ? '#DCDFF4' : 'transparent',
                        }}
                        className={styles.columnItem}
                        onClick={() => handleSelectObject(item.redmineId)}
                      >
                        <Typography variant="h4" color={'#082253'}>
                          {item.name}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
              <Grid item flex={1}>
                <Box className={styles.columnHeader}>
                  <Typography variant="h4" fontWeight={700} color={'#082253'}>
                    {intl.formatMessage({ id: 'objects_hierarchy_child_object_title' })}
                  </Typography>
                </Box>
                {childObjects &&
                  childObjects.map((item, index) => (
                    <ObjectsHierarchyColumnItem
                      key={index}
                      index={index}
                      item={item}
                      options={childObjectsOptions}
                      handleAction={handleColumnItemAction}
                      relation={ColumnTypes.CHILD}
                    ></ObjectsHierarchyColumnItem>
                  ))}
                <Box
                  sx={{ padding: '8px 16px', borderBottom: masterObjects.length > 1 ? '1px solid #E4E4EF' : 'none' }}
                >
                  <IconButton
                    onClick={() => handleAddColumnItem(ColumnTypes.CHILD)}
                    sx={{ padding: '0', opacity: disableAddChildObject ? '0.2' : 1 }}
                    disabled={disableAddChildObject}
                  >
                    <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
};
export default injectIntl(ObjectsHierarchy);
